import React from 'react';
import { useEffect } from 'react';
import MenuButton from '../components/MenuButton';
import './Art.css'; // Reuse the styles from Art.css

function Projects() {
    useEffect(() => {
        const preloadImage = new Image();
        preloadImage.src = `${process.env.PUBLIC_URL}/endstone1-c.jpg`;
      }, []);
    
      return (
    <div
    className="art-page"
    style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/endstone1-c.jpg'})`, // Correctly resolve gravel image from the public folder
        backgroundSize: '1500px 1000px', // Adjust this for zoomed-out effect
    }}
    >
      {/* Header with no overlay */}
      <header className="art-header">
        <h1>My Projects</h1>
      </header>

      {/* Main content with transparent black overlay */}
      <div className="art-overlay">
        <div className="art-section">
          <h2>Section 1</h2>
          <p>THIS PAGE IS UNDER CONSTRUCTION</p>
        </div>
        <div className="art-section">
          <h2>Section 2</h2>
          <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <div className="art-section">
          <h2>Section 3</h2>
          <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
        </div>
        <div className="art-section">
          <h2>Section 4</h2>
          <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>
        <div className="art-section">
          <h2>Section 5</h2>
          <p>Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, est eros bibendum elit.</p>
        </div>
      </div>

      {/* Footer with "Done" button */}
      <footer className="art-footer">
        <MenuButton to="/" className="menu-button done-button">Done</MenuButton>
      </footer>
    </div>
  );
}

export default Projects;
