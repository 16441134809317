import React from 'react';

function Resume() {
  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/dirt.png'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px',
        boxSizing: 'border-box',
      }}
    >
      <h1
        style={{
          color: '#fff',
          textShadow: '2px 2px 4px #000',
          marginBottom: '20px',
          fontFamily: 'Minecraftia, sans-serif',
        }}
      >
       My Resume
      </h1>
      <iframe
        src={`${process.env.PUBLIC_URL}/resume.pdf`}
        title="My Resume"
        style={{
          width: '80%',
          height: '80vh',
          border: '2px solid #000',
          boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
        }}
      />
    </div>
  );
}

export default Resume;
