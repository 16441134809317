import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const MenuButton = ({ to, children }) => {
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    // Create and preload the audio file
    const audioFile = new Audio(`${process.env.PUBLIC_URL}/click-sound.mp3`);
    audioFile.load(); // Preload the audio file
    setAudio(audioFile); // Save the audio file in state
  }, []);

  const playClickSound = () => {
    if (audio) {
      audio.currentTime = 0; // Reset audio to the start for repeated clicks
      audio.play().catch((error) => console.error('Audio playback failed:', error));
    }
  };

  return (
    <Link to={to} className="menu-button" onClick={playClickSound}>
      {children}
    </Link>
  );
};

export default MenuButton;
