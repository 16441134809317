import React, { useEffect } from 'react';
import * as PANOLENS from 'panolens';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './Will-Xia.png';
import './App.css';

// Import the custom MenuButton component
import MenuButton from './components/MenuButton';

// Import each page component
import Art from './pages/Art';
import Projects from './pages/Projects';
import AboutMe from './pages/AboutMe';
import Resume from './pages/Resume';
import ContactMe from './pages/ContactMe';

// Reuse a single viewer instance
let viewer = null;

function Home() {
  const quotes = [
    "Adventure awaits!",
    "May contain nuts!",
    "Tell your friends!",
    "Explore the unknown!",
    "Moderately attractive!",
    "Creativity unleashed!",
    "Satisfaction Guaranteed!",
    "Every block counts!",
    "Random splash text!",
    "Loved by millions!",
    "sqrt(-1) love you!"
  ];

  const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];

  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = process.env.PUBLIC_URL + '/test123.png';
  
    preloadImage.onload = () => {
      if (!viewer) {
        viewer = new PANOLENS.Viewer({
          container: document.getElementById('panorama-container'),
          autoRotate: true,
          autoRotateSpeed: 0.14,
          controlBar: false,
          cameraFov: 50,
        });
  
        const panorama = new PANOLENS.ImagePanorama(preloadImage.src);
        panorama.rotation.y = Math.PI / 1.1;
  
        panorama.addEventListener('error', (error) => {
          console.error('Panorama loading error:', error);
        });
  
        viewer.add(panorama);
      }
    };
  
    preloadImage.onerror = () => {
      console.error('Failed to load the panorama image');
    };
  
    return () => {
      if (viewer) {
        viewer.dispose();
        viewer = null;
      }
    };
  }, []);
  

  return (
    <div className="App">
      {/* Panorama container for the 360 view */}
      <div id="panorama-container" className="panorama-container"></div>

      <header className="App-header">
        <div className="logo-container">
          <img src={logo} className="Minecraft-logo" alt="Minecraft Title" />
          <span className="overlay-text">{randomQuote}</span>
        </div>

        <div className="button-container">
          <MenuButton to="/art">Art!</MenuButton>
          <MenuButton to="/projects">Projects</MenuButton>
          <MenuButton to="/aboutme">About Me</MenuButton>
        </div>

        <div className="extra-button-container">
          <MenuButton to="/resume">Resume</MenuButton>
          <MenuButton to="/contact-me">Contact Me</MenuButton>
        </div>
      </header>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/art" element={<Art />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/aboutme" element={<AboutMe />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/contact-me" element={<ContactMe />} />
      </Routes>
    </Router>
  );
}

export default App;
